import React from "react"
import Layout from "../components/layout"
import ContactUsMain from "../components/contactusmain"
import Content from "../components/content"
import Seo from "../components/seo"
import BackgroundImage from "../components/background"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <BackgroundImage />
    <Content/>
    <ContactUsMain/>
  </Layout>
)

export default IndexPage
