import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
//import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = () => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "hero.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const imageData = data.desktop.childImageSharp.fluid

  return (
    <BackgroundImage fluid={imageData} backgroundColor={`#00a79d`} className="hero is-medium is-grey-dark banner">
    <div className="hero-body heromobile">
    <div className="container">
        <h1 style={{color:"white"}} className="title content has-text-centered is-size-1 mb-6 is-size-3-mobile">
        Fusion Development
        </h1>
        <h2 className="subtitle content has-text-centered">
        <p className="is-size-3 is-size-5-mobile" style={{color:"white"}}>Discover<span style={{color:"#0a0a0a"}}>. </span>
        Design<span style={{color:"#0a0a0a"}}>. </span> 
        Deliver<span style={{color:"#0a0a0a"}}>.</span></p>
        </h2>
    </div>
    </div>
    </BackgroundImage>
  )
}

// const StyledBackgroundSection = styled(BackgroundSection)`
//   width: 100%;
//   background-position: center left;
//   height:300px;
// `

export default BackgroundSection